import React, { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useHistory, useParams } from 'react-router-dom';

// assets & components
import AboutECoachCard from 'components/pageECoach/aboutECoach/_index';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import ECoachSessionList from 'components/pageECoach/ECoachSessionList';
import ECoachSession from 'components/pageECoach/ECoachSession';
import FeedbackForm from 'components/pageECoach/FeedbackForm';
import Toolbar from 'components/pageECoach/Toolbar';

// constants
import routesConfig from 'constants/routesConfig.json';

// context
import { LoadingStatus, useFeedbackConsumer } from 'hooks/useFeedbackConsumer';

// hooks
import useApi from 'hooks/useApi';

// style
import Ecoach from 'components/pageECoach/_styles/_index.style';

const chatGuideSetup = {
  chatStartEnded: false,
  convoPrep: { with_who: '', goal: '', worried_by: '' },
  displayPromptHelper: false,
  displaySampleCases: false,
  inProgress: false,
  promptHelper: { goal: '', theme: '' },
};

export default function ECoachPage() {
  const history = useHistory();
  const posthog = usePostHog();
  const params = useParams();
  const { id } = params;

  const [activeSession, setActiveSession] = useState(null);
  const [activePage, setActivePage] = useState('chat'); // chat, about, history
  const [chatGuideData, setChatGuideData] = useState(chatGuideSetup);

  const { data: session, callApi: getSession } = useApi();

  useEffect(() => {
    if (id) getSession(`${routesConfig.ECOACH.SESSIONS}${id}`);
  }, [getSession, id]);

  useEffect(() => {
    if (session.status === 'success') setActiveSession(session.response);
  }, [session]);

  const { feedbackStatus, fetchFeedbackStatus, tryCreate } =
    useFeedbackConsumer();

  const resetChatGuideData = () => setChatGuideData(chatGuideSetup);

  const handleNewChat = (skipFeedbackFetching = false) => {
    if (!skipFeedbackFetching && activeSession) {
      fetchFeedbackStatus(activeSession.id);
    }

    resetChatGuideData();
    history.push({ pathname: routesConfig.E_COACH_PAGE, search: '' });
    setActivePage('chat');
  };

  const handleToolbarClick = (newPage) => {
    posthog?.capture(`user_clicks_${newPage}_in_toolbar`);
    if (newPage === 'new_chat') {
      handleNewChat();
      return;
    }
    setActivePage(newPage);
  };

  useEffect(() => {
    if (feedbackStatus.loadingStatus !== LoadingStatus.LOADED) return;
    if (!feedbackStatus.data.feedback_needed) setActiveSession(null);
  }, [feedbackStatus, setActiveSession]);

  const getPage = () => {
    if (activePage === 'about') return <AboutECoachCard />;
    if (activePage === 'history')
      return (
        <ECoachSessionList
          activeSession={activeSession?.id}
          getSession={getSession}
          handleNewChat={handleNewChat}
          resetChatGuideData={resetChatGuideData}
          setActivePage={setActivePage}
          setActiveSession={setActiveSession}
        />
      );
    return (
      <ECoachSession
        activeSession={activeSession}
        chatGuideData={chatGuideData}
        setChatGuideData={setChatGuideData}
        createSession={getSession}
      />
    );
  };

  return (
    <ErrorBoundary>
      <Ecoach>
        <Toolbar
          activePage={activePage}
          activeSession={activeSession}
          chatGuideData={chatGuideData}
          handleToolbarClick={handleToolbarClick}
        />
        <FeedbackForm
          feedbackStatus={feedbackStatus}
          handleCreate={(input) => {
            tryCreate(activeSession.id, input);
            setActiveSession(null);
            handleNewChat();
          }}
        />
        {getPage()}
      </Ecoach>
    </ErrorBoundary>
  );
}
