const UserInputType = {
  INITIAL_PROMPT: 0,
  CHALLENGE_VERIFICATION_ANSWER: 1,
  CHALLENGE_DESCRIPTION_FEEDBACK: 2,
  CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER: 3,
  BACK_AND_FORTH_CONVERSATION_INPUT: 4,
  CONVERSATION_PLAN_INPUT: 5,
  CONVERSATION_PREP_START: 11,
  CONVERSATION_PREP_WITH_WHO: 12,
  CONVERSATION_PREP_GOAL: 13,
  CONVERSATION_PREP_WORRIED_BY: 14,
};

const OutputType = {
  CHALLENGE_VERIFICATION_QUESTION: 0,
  CHALLENGE_DESCRIPTION: 1,
  CHALLENGE_VERIFICATION_CONFIRMATION_QUESTION: 2,
  CHALLENGE_VERIFICATION_CONFIRMATION_DECLINE_FOLLOW_UP: 3,
  CHALLENGE_VERIFICATION_SUGGESTION_TRANSITION: 4,
  BACK_AND_FORTH_CONVERSATION_RESPONSE: 5,
  SUGGESTIONS: 6,
  RAG_SUMMARY: 7,
  CONVERSATION_PLAN: 8,
  CONVERSATION_PLAN_ABOUT: 15,
  CONVERSATION_PREP_WITH_WHO: 16,
  CONVERSATION_PREP_GOAL: 17,
  CONVERSATION_PREP_WORRIED_BY: 18,
};

const ChallengeVerificationState = {
  IDLE: -1,
  ASK_FOR_ANSWER: 0,
  ASK_FOR_CONFIRMATION: 1,
  ASK_FOR_FEEDBACK: 2,
};

const UserFeatureFlagType = {
  ECOACH_CHALLENGE_CLARIFICATION: 'ecoach_challenge_clarification',
  ECOACH_QDRANT_RAG: 'ecoach_qdrant_rag',
};

const BinaryChoice = {
  NO: 'No',
  YES: 'Yes',
};

// eslint-disable-next-line import/prefer-default-export
export {
  UserInputType,
  OutputType,
  ChallengeVerificationState,
  UserFeatureFlagType,
  BinaryChoice,
};
