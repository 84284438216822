import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import ChatBubble from 'components/pageECoach/ChatBubble';
import ChatStart from 'components/pageECoach/chatStart/_index';
import ConversationItemsCard from 'components/pageECoach/convoPrep/ConversationItemsCard';
import IntroMessage from 'components/pageECoach/chatStart/IntroMessage';
import RAGCard from 'components/pageECoach/RAGCard';
import SuggestionItemsCard from 'components/pageECoach/suggestions/SuggestionItemsCard';
import { Stack } from '@mui/material';
import Loader from 'components/pageECoach/Loader';
import * as Page from 'components/pageECoach/_styles/ChatArea.style';

// context & hooks
import useWebsocketSetup from 'hooks/useWebsocketSetup';

// utils
import routesConfig from 'constants/routesConfig.json';
import { OutputType, UserInputType } from './utils';
import ECoachConfirmationInput from './ECoachConfirmationInput';
import AboutCard from './convoPrep/AboutCard';

export default function ECoachSession({
  activeSession,
  chatGuideData,
  createSession,
  setChatGuideData,
}) {
  const [inputPendingSend, setInputPendingSend] = useState();
  const [steps, setSteps] = useState(activeSession?.steps || []);

  const { sendJsonMessage, lastJsonMessage } = useWebsocketSetup(
    routesConfig.ECOACH.MAESTRO_WS
  );

  const sendUserInput = useCallback(
    (message, inputType = UserInputType.BACK_AND_FORTH_CONVERSATION_INPUT) => {
      if (!activeSession) {
        // Wait for session creation before sending input
        createSession(`${routesConfig.ECOACH.SESSIONS}`, 'post');
        setInputPendingSend({ inputType, message });
        setSteps([]);
        return;
      }

      setInputPendingSend(null);
      sendJsonMessage({
        action: 'evaluate',
        request_id: Date.now(),
        pk: activeSession.id,
        data: { input_type: inputType, message },
      });

      setSteps((prevSteps) => [...prevSteps, { user_inputs: [{ message }] }]);
    },
    [activeSession, createSession, sendJsonMessage]
  );

  // #region Send first input once session has been created
  useEffect(() => {
    if (activeSession && inputPendingSend) {
      sendUserInput(inputPendingSend.message, inputPendingSend.inputType);
    }
  }, [activeSession, inputPendingSend, sendUserInput]);
  // #endregion Send first input once session has been created

  // #region Load session steps
  useEffect(() => {
    if (!activeSession) setSteps([]);

    // Only override steps once session steps have been loaded
    if (activeSession?.steps && activeSession?.steps?.length !== 0) {
      setSteps(activeSession.steps);
    }

    // We don't want to update each time steps.length updates, so it's not in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession, setSteps]);
  // #endregion Load session steps

  // #region Receive eCoach response
  useEffect(() => {
    if (lastJsonMessage === null) return;
    if (lastJsonMessage.type === 'validation_error') return;

    const { data } = lastJsonMessage;

    if (!data) return;

    setSteps((prevSteps) => {
      // Remove inputs where output was not received as it will be included with output
      const filteredSteps = prevSteps.filter(
        (step) => step.outputs !== undefined
      );
      return [...filteredSteps, data];
    });
  }, [lastJsonMessage, setSteps]);
  // #endregion Receive eCoach response

  const messageCards = useMemo(() => {
    if (!activeSession) return [];
    return steps?.map((step) => {
      const userInputCards = [];
      if (step.user_inputs) {
        userInputCards.push(
          Object.values(step.user_inputs).map((userInput, index) => (
            <ChatBubble
              key={index}
              simpleString={userInput.message}
              pointer={true}
              sentByUser
            />
          ))
        );
      }

      const outputCards = [];
      if (step.outputs) {
        outputCards.push(
          Object.values(step.outputs).map((output, index) => {
            if (output.output_type === OutputType.CONVERSATION_PLAN_ABOUT)
              return <AboutCard key={index} />;

            if (output.output_type === OutputType.SUGGESTIONS)
              return (
                <SuggestionItemsCard
                  activeSession={activeSession}
                  stepOutput={output}
                  key={index}
                />
              );

            if (output.output_type === OutputType.RAG_SUMMARY)
              return (
                <RAGCard
                  activeSession={activeSession}
                  key={index}
                  output={output}
                />
              );

            if (output.output_type === OutputType.CONVERSATION_PLAN)
              return (
                <ConversationItemsCard
                  activeSession={activeSession}
                  chatGuideData={chatGuideData}
                  key={index}
                  stepOutput={output}
                />
              );

            return (
              <ChatBubble
                key={index}
                simpleString={output.message}
                pointer={true}
              />
            );
          })
        );
      } else {
        // As each step should have an output, show loading output if none exists yet (it's being generated)
        outputCards.push(
          <Page.ECoachSection>
            <ChatBubble pointer={true}>
              <Loader inline />
            </ChatBubble>
          </Page.ECoachSection>
        );
      }

      return [...userInputCards, ...outputCards];
    });
  }, [activeSession, chatGuideData, steps]);

  const inputComponent = useMemo(() => {
    if (!steps) return <></>;

    const lastStep = steps[steps.length - 1];
    if (!lastStep?.outputs) {
      // No input while waiting for eCoach response
      return <></>;
    }

    const lastOutput = lastStep.outputs[lastStep.outputs.length - 1];
    switch (lastOutput?.output_type) {
      case OutputType.CONVERSATION_PREP_WITH_WHO:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.CONVERSATION_PREP_WITH_WHO)
            }
          />
        );
      case OutputType.CONVERSATION_PREP_GOAL:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.CONVERSATION_PREP_GOAL)
            }
          />
        );
      case OutputType.CONVERSATION_PREP_WORRIED_BY:
        return (
          <Chatbar
            autoFocus
            onSubmit={(message) =>
              sendUserInput(message, UserInputType.CONVERSATION_PREP_WORRIED_BY)
            }
          />
        );
      case OutputType.CHALLENGE_VERIFICATION_CONFIRMATION_QUESTION:
        return (
          <ECoachConfirmationInput
            sendUserInput={sendUserInput}
            inputType={UserInputType.CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER}
          />
        );
      default:
        return <Chatbar autoFocus onSubmit={sendUserInput} />;
    }
  }, [sendUserInput, steps]);

  return (
    <Stack spacing={3} style={{ flex: 1, justifyContent: 'space-between' }}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap: '30px',
        }}
      >
        <IntroMessage key="IntroMessage" />
        {activeSession && messageCards}
      </div>
      {!activeSession && (
        <ChatStart
          key="start_chat"
          chatGuideData={chatGuideData}
          sendUserInput={sendUserInput}
          setChatGuideData={setChatGuideData}
        />
      )}
      {activeSession && inputComponent}
    </Stack>
  );
}

ECoachSession.propTypes = {
  activeSession: PropTypes.object,
  chatGuideData: PropTypes.object,
  createSession: PropTypes.func,
  setChatGuideData: PropTypes.func,
};
