import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// assets & components
import { Communication, Files } from 'untitledui-js';

// styles
import * as Styled from 'components/pageECoach/_styles/Toolbar.style';

export default function Toolbar(props) {
  const { activePage, activeSession, chatGuideData, handleToolbarClick } =
    props;
  const { t } = useTranslation('pageEcoach');

  return (
    <Styled.Toolbar>
      {(activeSession || chatGuideData.inProgress) && (
        <Styled.ToolbarButton onClick={() => handleToolbarClick('new_chat')}>
          <Communication.MessagePlusSquare size={'15'} />
          {t('New chat')}
        </Styled.ToolbarButton>
      )}
      <Styled.ToolbarButton
        active={activePage === 'chat'}
        onClick={() => handleToolbarClick('chat')}
      >
        <Communication.MessageChatSquare size={'15'} />
        {t('Chat')}
      </Styled.ToolbarButton>
      <Styled.ToolbarButton
        active={activePage === 'history'}
        onClick={() => handleToolbarClick('history')}
      >
        <Files.Box size={'15'} />
        {t('History')}
      </Styled.ToolbarButton>
      <Styled.ToolbarButton
        active={activePage === 'about'}
        onClick={() => handleToolbarClick('about')}
      >
        <Files.FileQuestion02 size={'15'} />
        {t('About')}
      </Styled.ToolbarButton>
    </Styled.Toolbar>
  );
}

Toolbar.propTypes = {
  activePage: PropTypes.string,
  activeSession: PropTypes.object,
  chatGuideData: PropTypes.object,
  handleToolbarClick: PropTypes.func,
};
